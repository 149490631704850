<template>
    <div>
        <div class="card" :class="{'drag-over': $refs.upload && $refs.upload.dropActive}">
            <div class="card-header">
                <slot></slot>
            </div>
            <div class="card-body text-center">
                <h4 class="card-title" v-if="caption">{{caption}}</h4>
                <template v-for="f in files"  :key="f.id">
                    <div v-if="files.length" class="file-status">
                        <span>{{f.name}}</span> -
                        <span>{{$filters.numberFormat(f.size )}} bytes</span>
                        <span v-if="f.error" class="badge">エラー: {{f.error}}</span>
                        <span v-else-if="f.success" class="badge">完了</span>
                        <span v-else-if="f.active" class="badge">実行中</span>
                        <span v-else></span>
                    </div>
                    <div id="progress" class="progress">
                        <div
                            class="progress-bar progress-bar-success progress-bar-striped active"
                            :style="'width:' + f.progress + '%'">{{f.progress}}%</div>
                    </div>
                </template>
                <div class="mt-1">
                    <file-upload
                        class="btn btn-primary"
                        :post-action="url"
                        :multiple="multiple"
                        :maximum="maximum"
                        :drop="drop"
                        :drop-directory="dropDirectory"
                        :data="params"
                        :headers="{'X-CSRF-TOKEN': token}"
                        v-model="files"
                        :name="name"
                        :extensions="extensions"
                        ref="upload"
                        @input-file="inputFile">
                            <i class="fa-solid fa-plus"></i>
                            ファイル選択
                        </file-upload>
                        <span v-if="!spontaneus">
                            <button type="button" class="btn btn-success" v-if="$refs.upload && !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                                <i class="fa-solid fa-upload"></i>
                                アップロード開始
                            </button>
                            <button type="button" class="btn btn-danger"  v-else @click.prevent="$refs.upload.active = false">
                                <i class="fa-solid fa-stop"></i>
                                アップロード停止
                            </button>
                        </span>
                        <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear()">
                            <i class="fa-solid fa-xmark"></i>
                            クリア
                        </button>
                </div>
            </div>
        </div>
        <div id="drop-screen"></div>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style lang="scss">
    #drop-screen {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -999;
    }
    .panel-default {
        margin: 5px;
    }
    .drag-over {
        border: 5px dashed #ddd;
        margin: 1px;
    }
    .file-status {
        margin: .7em;
        padding: .7em;
        background-color: teal;
        color: snow;
        border-radius: .3em;
    }
</style>

<script>
    import FileUpload from 'vue-upload-component';
    export default {
        components: { FileUpload },
        props: {
            url: {
                required: true
            },
            // ドロップエリアのセレクタを文字列で渡す
            drop: {
                default: "body"
            },
            dropDirectory: {
                default: false
            },
            name: {
                default: "csv"
            },
            multiple: {
                default: false
            },
            maximum: {
                default: 1
            },
            spontaneus: {
                default: false
            },
            caption: {
                type: String,
                default: "ファイルをドロップ"
            },
            extensions: {
                default: "csv,txt,xlsx",
            },
        },
        data() {
            return {
                files: [],
                token: $('meta[name="csrf-token"]').attr("content"),
                params: {
                    uid: null
                },
                isLoading: false,
            }
        },
        methods: {
            unique(strong = 1000){
                return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16)
            },
            inputFile(n, o) {
                let uploaded = true;
                let uploadable = true;
                if (!n) {
                    uploaded = false;
                    uploadable = false;
                }
                if (n && !n.active) {
                    this.params.uid = this.unique();
                }
                for (let i in this.files) {
                    if (!this.files[i].success) {
                        uploaded = false;
                    }
                    if (!this.files[i].active && this.files[i].error) {
                        Alert.err(this.files[i].response);
                        this.isLoading = false;
                        break;
                    }
                    if (this.files[i].active || parseInt(this.files[i].progress)) {
                        uploadable = false;
                        this.isLoading = true;
                    }
                }
                if (uploaded) {
                    if (this.files[0].response) {
                        this.$emit("uploaded", this.files[0].response);
                    } else {
                        this.$emit("uploaded");
                    }
                    this.isLoading = false;
                } else if (uploadable && this.spontaneus) {
                    this.$refs.upload.active = true;
                }
            },
            clear() {
                this.$refs.upload.clear();
            }
        },
        mounted() {
            this.params.uid = this.unique();
        }
    }
</script>