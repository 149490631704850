<template>
    <div id="page">
        <sidemenu @toggle="toggle" @recent="recent"></sidemenu>
        <div class="page" :class="{closed: isClosed}">
            <navbar ref="navbar"></navbar>
            <router-view />
        </div>
    </div>
</template>

<style scoped lang="scss">
.page {
    padding-left: 260px;
    transition: 0.2s ease;
    &.closed {
        padding-left: 34px;
    }
}
@media screen and (max-width: 770px) {
    .page {
        padding-left: 0px;
    }
}
</style>

<script>
import sidemenu from "./sidemenu";
import navbar from "components/navbar";
export default {
    components: {
        sidemenu,
        navbar
    },
    data() {
        return {
            isClosed: false,
        }
    },
    methods: {
        toggle(ev) {
            this.isClosed = ev;
        },
        recent() {
            this.$refs.navbar.recent();
        }
    },
}
</script>