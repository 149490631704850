<template>
    <div class="container-fluid">
        <legend class="mb-3">稟議提出</legend>
        <div class="mb-3 d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <div class="sticky mb-3">
            <pagination v-bind="approvals" @goto="goto($event)" :showTotal="true"></pagination>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="btn-th">編集</th>
                        <th>承認</th>
                        <th>添付ファイル</th>
                        <th class="nowrap">ユーザー名</th>
                        <th>発注先名</th>
                        <th class="nowrap">受取手段</th>
                        <th>内容</th>
                        <th>金額(内訳)</th>
                        <th class="sortable" @click="sort('entry_date')">
                            記入日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'entry_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'entry_date' && !isAsc"></i>
                        </th>
                        <th class="sortable" @click="sort('payment_schedule_date')">
                            支払予定日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'payment_schedule_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'payment_schedule_date' && !isAsc"></i>
                        </th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody v-if="approvals && approvals.total > 0">
                    <tr v-for="a in approvals.data" :key="a.id" :class="{'deleted': a.deleted_at}">
                        <td>
                            <button class="btn btn-outline-secondary btn-sm" @click="edit(a.id)" :disabled="a.deleted_at || a.is_approval">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td>
                            <i class="fa-solid fa-check text-muted" v-if="a.is_approval"></i>
                        </td>
                        <td>
                            <button class="btn btn-sm" :class="{
                                'btn-outline-secondary': a.zero_approval_file.length == 0,
                                'btn-outline-info': a.zero_approval_file.length > 0,
                            }" @click="download(a)" :disabled="a.deleted_at">
                                <i class="fa-solid fa-paperclip"></i>
                                {{a.zero_approval_file.length}} 件
                            </button>
                        </td>
                        <td class="small nowrap">
                            {{a.user.name}}
                        </td>
                        <td class="mid">
                            {{a.zero_supplier.supplier_name}}
                        </td>
                        <td>
                            {{a.zero_approval_acceptance.acceptance}}
                        </td>
                        <td>
                            {{a.content}}
                        </td>
                        <td class="small nowrap">
                            {{$filters.numberFormat(a.money)}}
                            <div>
                                {{totalBreakdown(a.zero_breakdown)}}
                            </div>
                        </td>
                        <td class="small nowrap">
                            {{a.entry_date}}
                        </td>
                        <td class="small nowrap">
                            {{a.payment_schedule_date}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger btn-sm" @click="toggle(a)"  :disabled="a.is_approval" v-if="!a.deleted_at">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success btn-sm" @click="toggle(a)" :disabled="a.is_approval" v-else>
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="12">
                            あなたの稟議はありません。追加してください。
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <approvals-editor
            :approval_id="approval_id"
            @saved="reload()"></approvals-editor>
        <download-approval
            :approval_id="approval_id"
            @changed="reload()"></download-approval>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style scoped lang="scss">
.sortable {
    color: darkgreen;
    cursor: pointer;
    &:hover {
        background-color: #fbebee;
    }
}
</style>

<script>
import approvalsEditor from './addApprovalsEditor.vue'
import downloadApproval from '../downloadApproval/downloadApproval.vue'
export default {
    components: {
        approvalsEditor,
        downloadApproval,
    },
    data() {
        return {
            approvals: {},
            approval_id: null,
            approvalModal: null,
            downloadModal: null,
            keywords: null,
            page: parseInt(this.$route.query.page || 1),
            orderBy: null,
            isAsc: true,
            isLoading: false,
        }
    },
    methods: {
        sort(orderBy = null) {
            if (orderBy && this.orderBy == orderBy) {
                if (this.isAsc) {
                    this.isAsc = false;
                } else {
                    this.orderBy = null;
                    this.isAsc = true;
                }
            } else {
                this.orderBy = orderBy;
                this.isAsc = true;
            }
            this.reload();
        },
        goto(n = 1) {
            this.page = n;
            this.reload();
        },
        reload() {
            this.isLoading = true;
            axios.get("/tools/approvals/addlists", {params: {
                page: this.page,
                orderBy: this.orderBy,
                isAsc: this.isAsc ? 1 : 0,
                user_mode: "self",
            }}).then(res => {
                this.approvals = res.data;
                this.isLoading = false;
            }).catch(err => {Alert.err(err)});
        },
        edit(id = null) {
            this.approval_id = id;
            setTimeout(() => this.approvalModal.show());
        },
        download(v) {
            this.approval_id = v.id;
            setTimeout(() => this.downloadModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal({
                title: `稟議${action}`,
                text: `指定の稟議を${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/tools/approvals/toggle", {id: item.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        transferExport() {
            if (!this.payment_schedule_date) {
                Alert.err("支払予定日が選択されていません");
                return;
            }
            axios.post("/tools/approvals/transfer", {date: this.payment_schedule_date}).then(res => {
                location.href = `/tools/approvals/transferdownload/?fname=${res.data.singleFname}`;
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        uploaded() {
            this.reload();
        },
        totalBreakdown(breakdowns) {
            return this.$filters.numberFormat(_.sumBy(breakdowns, (o) => {
                return parseInt(o.money);
            }));
        },
    },
    mounted() {
        this.reload();
        this.approvalModal = new bootstrap.Modal(document.getElementById("add-approval-modal"));
        this.downloadModal = new bootstrap.Modal(document.getElementById("download-modal"));
    },
}
</script>
