<template>
    <div class="container-fluid">
        <legend class="mb-3 d-flex justify-content-between">
            稟議承認
            <div class="justify-content-center">
                <span :class="{'text-danger': count_unapproved, 'text-success': !count_unapproved}">本日の未承認件数：{{ count_unapproved }}件</span>
            </div>
            <button class="btn btn-outline-danger" @click="downloadAll()" :disabled="downloading">
                <i class="fa-solid fa-cloud-arrow-down"></i>
                全稟議出力
            </button>

        </legend>
        <div class="container">
            <div class="row justify-content-center mb-3">
                <div class="col-md-10">
                    <input type="search" v-model="keywords" class="form-control form-control-lg" @keydown="find($event)" placeholder="検索">
                </div>
            </div>
        </div>

        <div class="mb-3 d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{active: !is_all}" @click.prevent="allApproval(0)">
                    <i class="fa-solid fa-square-check" v-if="!is_all"></i>
                    <i class="fa-regular fa-square" v-else></i>
                    有効なもの
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{active: is_all}" @click.prevent="allApproval(1)">
                    <i class="fa-solid fa-square-check" v-if="is_all"></i>
                    <i class="fa-regular fa-square" v-else></i>
                    すべて
                </a>
            </li>
        </ul>
        <div class="sticky mb-3">
            <pagination v-bind="approvals" @goto="goto($event)" :showTotal="true"></pagination>
        </div>
        <div class="table-responsive-lg">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="btn-th">編集</th>
                        <th class="sortable nowrap" @click="sort('is_approval')">
                            承認
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'is_approval' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'is_approval' && !isAsc"></i>
                        </th>
                        <th class="nowrap">添付ファイル</th>
                        <th class="nowrap">ユーザー名</th>
                        <th class="nowrap">発注先名</th>
                        <th class="nowrap">受取手段</th>
                        <th class="nowrap">分類/詳細</th>
                        <th class="nowrap">内容</th>
                        <th class="nowrap">金額(内訳)</th>
                        <th class="sortable nowrap" @click="sort('entry_date')">
                            記入日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'entry_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'entry_date' && !isAsc"></i>
                        </th>
                        <th class="sortable nowrap" @click="sort('payment_schedule_date')">
                            支払予定日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'payment_schedule_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'payment_schedule_date' && !isAsc"></i>
                        </th>
                        <th class="sortable nowrap" @click="sort('target_month')">
                            対象月
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'target_month' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'target_month' && !isAsc"></i>
                        </th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="a in approvals.data" :key="a.id" :class="{'deleted': a.deleted_at}">
                        <td>
                            <button v-if="!a.is_approval" class="btn btn-outline-secondary btn-sm" @click="edit(a.id)" :disabled="a.deleted_at || a.is_approval">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td>
                            <i class="fa-solid fa-check text-muted" v-if="a.is_approval" @click.ctrl.shift.alt="edit(a.id)"></i>
                            <i class="fa-solid fa-thumbs-up text-success" v-else @click="approve(a)" :class="{'pointer': !a.deleted_at}"></i>
                        </td>
                        <td>
                            <button class="btn btn-sm" :class="{
                                'btn-outline-secondary': a.zero_approval_file.length == 0,
                                'btn-outline-info': a.zero_approval_file.length > 0,
                            }" @click="download(a)" :disabled="a.deleted_at">
                                <i class="fa-solid fa-paperclip"></i>
                                {{a.zero_approval_file.length}} 件
                            </button>
                        </td>
                        <td class="small">
                            {{a.user.name}}
                        </td>
                        <td>
                            {{a.zero_supplier.supplier_name}}
                            <i class="fa-solid fa-arrow-up-right-from-square pointer" @click="supplierLink(a.zero_supplier)"></i>
                        </td>
                        <td>
                            {{a.zero_approval_acceptance.acceptance}}
                        </td>
                        <td class="small">
                            <span v-if="a.zero_approval_classification">
                                {{a.zero_approval_classification.classification_name}}
                            </span>
                            <span v-if="a.zero_approval_classification_detail">
                                / {{a.zero_approval_classification_detail.classification_detail_name}}
                            </span>
                        </td>
                        <td>
                            {{a.content}}
                        </td>
                        <td>
                            {{$filters.numberFormat(a.money)}}
                            <div class="small">
                                ({{totalBreakdown(a.zero_breakdown)}})
                            </div>
                        </td>
                        <td class="small nowrap">
                            {{a.entry_date}}
                        </td>
                        <td class="small nowrap">
                            {{a.payment_schedule_date}}
                        </td>
                        <td class="small nowrap">
                            {{ym(a.target_month)}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger btn-sm" @click="toggle(a)" v-if="!a.deleted_at" :disabled="a.is_approval">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success btn-sm" @click="toggle(a)" v-else :disabled="a.is_approval">
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <approvals-editor
            :approval_id="approval_id"
            :is_approval="true"
            @saved="reload()"></approvals-editor>
        <download-approval
            :approval_id="approval_id"
            :is_approval="is_approval"
            @changed="reload()"></download-approval>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style scoped lang="scss">
.sortable {
    color: darkgreen;
    cursor: pointer;
    &:hover {
        background-color: #fbebee;
    }
}
    .schedule {
        height: 30px;
    }
    .export-button {
        margin-left: 10px;
    }
</style>

<script>
import approvalsEditor from '../addApprovals/addApprovalsEditor.vue'
import downloadApproval from '../downloadApproval/downloadApproval.vue'
export default {
    components: {
        approvalsEditor,
        downloadApproval,
    },
    data() {
        return {
            approvals: {},
            approval_id: null,
            approvalModal: null,
            downloadModal: null,
            keywords: null,
            page: parseInt(this.$route.query.page || 1),
            orderBy: null,
            isAsc: true,
            isLoading: false,
            is_all: 0,
            payment_schedule_date: dayjs().format("YYYY-MM-DD"),
            period_of_payment_schedule: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
            downloading: false,
            is_approval: 0,
            count_unapproved: 0,
        }
    },
    methods: {
        allApproval(is_all) {
            this.is_all = is_all;
            this.reload();
        },
        find(ev) {
            if (!ev.isComposing && ev.code == "Enter") {
                this.page = 1;
                this.reload();
            }
        },
        sort(orderBy = null) {
            if (orderBy && this.orderBy == orderBy) {
                if (this.isAsc) {
                    this.isAsc = false;
                } else {
                    this.orderBy = null;
                    this.isAsc = true;
                }
            } else {
                this.orderBy = orderBy;
                this.isAsc = true;
            }
            this.reload();
        },
        goto(n = 1) {
            this.page = n;
            this.reload();
        },
        reload() {
            this.isLoading = true;
            axios.get("/tools/approvals/countUnapproved").then(res => {
                this.count_unapproved = res.data;
            });

            axios.get("/tools/approvals/addlists", {params: {
                keywords: this.keywords,
                page: this.page,
                orderBy: this.orderBy,
                isAsc: this.isAsc ? 1 : 0,
                user_mode: "all",
                is_all: this.is_all,
            }}).then(res => {
                this.approvals = res.data;
                this.isLoading = false;
            }).catch(err => {Alert.err(err); this.isLoading = false;});
        },
        edit(id = null) {
            this.approval_id = id;
            setTimeout(() => this.approvalModal.show());
        },
        download(v) {
            this.approval_id = v.id;
            this.is_approval = v.is_approval;
            setTimeout(() => this.downloadModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal({
                title: `稟議${action}`,
                text: `指定の稟議を${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/tools/approvals/toggle", {id: item.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        downloadPayment() {
            if (!this.payment_schedule_date) {
                Alert.err("支払予定日が選択されていません");
                return;
            }
            axios.post("/tools/approvals/transfer", {date: this.payment_schedule_date}).then(res => {
                location.href = `/tools/approvals/transferdownload/?date=${this.payment_schedule_date}`;
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        downloadBreakdown() {
            if (!this.period_of_payment_schedule ||  this.period_of_payment_schedule.length != 2) {
                Alert.err("支払予定日が選択されていません");
                return;
            }
            axios.post("/tools/approvals/checkbreakdownload", {date: this.period_of_payment_schedule}).then(res => {
                location.href = "/tools/approvals/breakdowndownload/?date=" + encodeURI(this.period_of_payment_schedule);
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        downloadAll() {
            this.downloading = true;
            setTimeout(() => {this.downloading = false}, 10 * 1000);
            location.href = "/tools/approvals/allapprovaldownload";
        },
        approve(a) {
            // 削除済みの場合、開かないようにする
            if (a.deleted_at) return;
            swal({
                title: "稟議承認",
                text: "指定の稟議を承認します。\nよろしいですか？",
                icon: "info",
                buttons: ["キャンセル" , "承認"],
                dangerMode: false,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/tools/approvals/approval", {id: a.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => {
                        Alert.err(err);
                    });
                }
            });
        },
        uploaded() {
            this.reload();
        },
        totalBreakdown(breakdowns) {
            return this.$filters.numberFormat(_.sumBy(breakdowns, (o) => {
                return parseInt(o.money);
            }));
        },
        ym(ym) {
            if (!ym) return "";
            const year = ym.toString().substring(0, 4);
            const month = ym.toString().substring(4, 6);
            return year + "年" + month + "月";
        },
        supplierLink(supplier) {
            location.href = `/master/suppliers/?keywords=${supplier.supplier_name}`;
        },
    },
    watch: {
        keywords(n, o) {
            if (o && o.length > 0 && n.length === 0) {
                this.reload();
            }
        }
    },
    mounted() {
        this.reload();
        this.approvalModal = new bootstrap.Modal(document.getElementById("add-approval-modal"));
        this.downloadModal = new bootstrap.Modal(document.getElementById("download-modal"));
    },
}
</script>
