import { createRouter, createWebHistory } from 'vue-router'


export default createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: require('views/index.vue').default,
            children: [
                { path: '', component: require('views/top.vue').default },
                { path: '/master/approval/categories', component: require('views/master/approval/categories/categories.vue').default },
                { path: '/master/classification', component: require('views/master/classification/classification.vue').default },
                { path: '/master/classification/detail/:id', component: require('views/master/classificationDetail/classificationDetail.vue').default },
                { path: '/master/suppliers', component: require('views/master/suppliers/suppliers.vue').default },
                { path: '/master/managers', component: require('views/master/managers/managers.vue').default },
                { path: '/master/menus', component: require('views/master/menus/menus.vue').default },
                { path: '/tools/addapprovals', component: require('views/tools/addApprovals/addApprovals.vue').default },
                { path: '/tools/checkapprovals', component: require('views/tools/checkApprovals/checkApprovals.vue').default },
                { path: '/tools/editapprovals', component: require('views/tools/checkApprovals/checkApprovals.vue').default },
                { path: '/tools/managers', component: require('views/tools/managerApprovals/managerApprovals.vue').default },
            ]
        },
        // { path: '/403', component: require('views/403.vue').default },
        { path: '/:any*', component: require('views/404.vue').default },
        ]
});