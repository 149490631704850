<template>
    <div class="container">
        <legend>
            発注先マスタ
        </legend>
        <div class="mb-3 px-5">
            <input type="search" class="form-control form-control-lg" v-model="keywords" @keydown="find($event)" placeholder="検索">
        </div>
        <div class="mb-3 d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{active: !is_all}" @click.prevent="allSuppliers(0)">
                    <i class="fa-solid fa-square-check" v-if="!is_all"></i>
                    <i class="fa-regular fa-square" v-else></i>
                    有効なもの
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{active: is_all}" @click.prevent="allSuppliers(1)">
                    <i class="fa-solid fa-square-check" v-if="is_all"></i>
                    <i class="fa-regular fa-square" v-else></i>
                    すべて
                </a>
            </li>
        </ul>
        <div class="sticky">
            <pagination v-bind="suppliers" @goto="goto($event)" :showTotal="true"></pagination>
        </div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="btn-th">編集</th>
                        <th>契約書</th>
                        <th>支払先名</th>
                        <th>
                            <input type="file" multiple ref="attach">
                        </th>
                        <th>金融機関名</th>
                        <th>支店名</th>
                        <th>口座種別</th>
                        <th>口座番号</th>
                        <th>口座名義</th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="s in suppliers.data" :key="s.id" :class="{'deleted': s.deleted_at}">
                        <td>
                            <button class="btn btn-outline-secondary" @click="edit(s.id)" :disabled="s.deleted_at">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-sm" :class="{
                                'btn-outline-secondary': s.zero_contracts.length == 0,
                                'btn-outline-info': s.zero_contracts.length > 0,
                            }" @click="download(s)">
                                <i class="fa-solid fa-paperclip"></i>
                                {{s.zero_contracts.length}} 件
                            </button>
                        </td>
                        <td class="big">
                            {{s.supplier_name}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" @click="contractSave(s.id)" :disabled="s.deleted_at">
                                <span>←に添付処理実行</span>
                            </button>
                        </td>
                        <td>
                            {{s.bank_name}}
                        </td>
                        <td>
                            {{s.branch_name}}
                        </td>
                        <td>
                            {{s.type_of_account}}
                        </td>
                        <td>
                            {{s.account_number}}
                        </td>
                        <td>
                            {{s.account_holder}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" @click="toggle(s)" v-if="!s.deleted_at">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success" @click="toggle(s)" v-else>
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <suppliers-editor
            :supplier_id="supplier_id"
            @saved="reload()"></suppliers-editor>
        <suppliers-download
            :supplier_id="supplier_id"
            @changed="reload()"></suppliers-download>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<script>
import suppliersEditor from './suppliersEditor.vue'
import suppliersDownload from './suppliersDownload.vue'
export default {
    components: {
        suppliersEditor,
        suppliersDownload,
    },
    data() {
        return {
            suppliers: [],
            supplier: {id: null, paths: null},
            page: this.$route.query.page || 1,
            supplier_id: null,
            keywords: this.$route.query.keywords,
            supplierModal: null,
            is_all: 0,
            approval_id: null,
            isLoading: false,
        }
    },
    methods: {
        allSuppliers(is_all) {
            this.is_all = is_all;
            this.reload();
        },
        find(ev) {
            if (!ev.isComposing && ev.code == "Enter") {
                this.page = 1;
                this.reload();
            }
        },
        goto(n) {
            this.page = n;
            this.reload();
        },
        reload() {
            this.isLoading = true;
            axios.get("/master/suppliers/lists", {params: {
                page: this.page,
                keywords: this.keywords,
                is_all: this.is_all,
            }}).then(res => {
                this.suppliers = res.data;
                this.isLoading = false;
            }).catch(err => {
                Alert.err(err);
                this.isLoading = false;
            });
        },
        edit(id = null) {
            this.supplier_id = id;
            setTimeout(() => this.supplierModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal ({
                title: `発注先マスタ${action}`,
                text: `指定のレコードを${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/suppliers/toggle", {id: item.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => {
                        Alert.err(err.response);
                    });
                }
            });
        },
        download(v) {
            this.supplier_id = v.id;
            setTimeout(() => this.downloadModal.show());
        },
        contractSave(id) {
            this.supplier.id = id;
            const formData = new FormData();
            for (let i in this.$refs.attach.files) {
                const file = this.$refs.attach.files[i];
                formData.append(i, file);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            axios.post("/master/suppliers/upload", formData, config).then(res => {
                this.supplier.paths = res.data;
                axios.post("/master/suppliers/contractSave", this.supplier).then(res => {
                    Alert.msg(res.data);
                    this.reload();
                }).catch(err => {
                    Alert.err(err);
                });
            }).catch(err => {
                Alert.err(err);
            });
        }
    },
    watch: {
        keywords(n, o) {
            if (o && o.length > 0 && n.length === 0) {
                this.reload();
            }
        }
    },
    mounted() {
        this.reload();
        this.supplierModal = new bootstrap.Modal(document.getElementById("supplier-modal"));
        this.downloadModal = new bootstrap.Modal(document.getElementById("download-modal"));
    },
}
</script>
