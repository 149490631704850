<template>
    <datepicker
        :modelValue="modelValue"
        @update:modelValue="$event => $emit('update:modelValue', $event)"
        :month-change-on-scroll="false"
        format="yyyy-MM-dd"
        range
        multi-calendars
        :clearable="true"
        :auto-apply="true"
        :time-picker="false"

        locale="ja"
        :hide-navigation="['time']"
        model-type="format"
        :placeholder="placeholder"></datepicker>
</template>

<script>
export default {
    props: {
        placeholder: {
            default: ""
        },
        modelValue: {}
    }
}
</script>