<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" :id="id">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <i class="fa-solid fa-pencil"></i>
                        <span v-if="adv_media_id">広告メディア編集</span>
                        <span v-else>広告メディア追加</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label>管理者</label>
                        <div>
                            <vue-select
                                class="form-select"
                                :options="users"
                                label-by="name"
                                value-by="id"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                placeholder="実行名を選択"
                                search-placeholder="文字入力で検索"
                                v-model="manager.user_id"></vue-select>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>対象者</label>
                        <div>
                            <vue-select
                                class="form-select"
                                :options="users"
                                label-by="name"
                                value-by="id"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                placeholder="対象名を選択"
                                search-placeholder="文字入力で検索"
                                v-model="manager.check_user_id"></vue-select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-primary" @click="save()">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        id: {
            default: "manager-editor"
        },
        manager_id: {
            default: null
        },
        manager: {
                user_id: null,
                check_user_id: null,
        },
    },
    data() {
        return {
            manager: {},
            users: [],
        }
    },
    methods: {
        init() {
            this.users = [];
            axios.get("/tools/approvals/userlists").then(res => {
                for (let i in res.data) {
                    let userlists = res.data[i];
                    this.users.push({
                        name: userlists.name + '(' + userlists.email + ')',
                        id: userlists.id,
                    });
                }
            });
        },
        save() {
            axios.post("/master/managers/save", this.manager).then(res => {
                Alert.msg(res.data);
            }).catch(err => {
                Alert.err(err.response);
            }).finally(() => {
                $(`#${this.id}`).modal("hide");
                this.$emit("reload");
            })
        }
    },
    mounted() {
        this.init();
    }
}
</script>