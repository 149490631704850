<template>
    <div class="container">
        <div class="mb-3">
            <router-link to="/master/classification">
                <i class="fa-solid fa-backward"></i>
                戻る
            </router-link>
        </div>
        <legend>
            分類詳細マスタ:
            <span class="fw-bold text-primary" v-if="classification.id">{{classification.classification_name}}</span>
        </legend>
        <div class="d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="btn-th"></th>
                        <th class="btn-th">編集</th>
                        <th>分類詳細名称</th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody v-if="details.length > 0">
                    <tr v-for="d in ordered" :key="d.id" :class="{'deleted': d.deleted_at}" class="draggable"
                            draggable="true"
                            @dragstart="dragstart(d, $event)"
                            @dragend="dragend"
                            @dragenter="dragenter(d)">
                        <td>
                            <i class="fa-solid fa-grip-vertical"></i>
                        </td>
                        <td>
                            <button class="btn btn-outline-secondary" @click="edit(d.id)" :disabled="d.deleted_at">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td class="big">
                            {{d.classification_detail_name}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" @click="toggle(d)" v-if="!d.deleted_at">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success" @click="toggle(d)" v-else>
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="3" class="text-center p-5">
                            分類詳細はありません。
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <classification-detail-editor
            :classification_detail_id="classification_detail_id"
            :classification_id="$route.params.id"
            @saved="reload"></classification-detail-editor>
    </div>
</template>

<script>
import classificationDetailEditor from './classificationDetailEditor.vue'
export default {
    components: {
        classificationDetailEditor,
    },
    data() {
        return {
            details: [],
            classification: {},
            classification_detail_id: null,
            classificationDetailModal: null,
        }
    },
    methods: {
        reload() {
            axios.get("/master/classification/edit", {params: {id: this.$route.params.id}}).then(res => {
                this.classification = res.data;
            }).catch(err => {Alert.err(err)});
            axios.get("/master/classificationdetail/lists", {params: {
                classification_id: this.$route.params.id
            }}).then(res => {
                this.details = res.data;
            }).catch(err => {Alert.err(err)});
        },
        edit(id = null) {
            this.classification_detail_id = id;
            setTimeout(() => this.classificationDetailModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal ({
                title: `分類詳細マスタ${action}`,
                text: `指定のレコードを${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/classificationdetail/toggle", {id: item.id}).then(res => {
                        this.reload();
                        Alert.msg(res.data);
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        // ドラッグStart
        dragstart(item, e) {
            this.drag = {};
            // 掴んでいる情報を設定
            this.drag = item;
        },
        // ドラッグend
        dragend(e) {
            axios.post("/master/classificationdetail/sort", {details: this.details}).then(res => {
                this.reload();
            }).catch(err => {
                Alert.err(err);
            });
        },
        // ドラッグenter
        dragenter(item) {
            // 最後にホバーしたIDを記録
            let tmp = item.order_number
            item.order_number = this.drag.order_number
            this.drag.order_number = tmp
            return
        },
    },
    computed: {
        ordered() {
            return _.orderBy(this.details, "order_number");
        }
    },
    mounted() {
        this.classificationDetailModal = new bootstrap.Modal(document.getElementById("classification-detail-modal"));
        this.reload()
    },
}
</script>
