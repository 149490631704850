<template>
    <div class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" :id=id data-bs-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span class="fa-solid fa-pencil"></span>
                        <span v-if="supplier.id">発注先編集</span>
                        <span v-else>発注先新規追加</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label>発注先名</label>
                        <input type="text" v-model="supplier.supplier_name" class="form-control form-control-lg" id="supplier-name">
                    </div>
                    <div class="mb-3">
                        <label>金融機関名</label>
                        <input type="text" v-model="supplier.bank_name" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label>支店名</label>
                        <input type="text" v-model="supplier.branch_name" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label>口座種別</label>
                        <select v-model="supplier.type_of_account" class="form-select">
                            <option v-for="(t, i) in types_of_account" :key="i" :value="t">{{t}}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label>口座番号</label>
                        <input type="text" v-model="supplier.account_number" class="form-control form-control-lg font-monospace">
                    </div>
                    <div class="mb-3">
                        <label>口座名義</label>
                        <input type="text" v-model="supplier.account_holder" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label>契約書取込</label>
                        <div v-for="f in supplier.zero_contracts" :key="f.id" class="file-list">
                            {{f.contract_name}}
                            <i class="fa-solid fa-xmark text-danger fw-bold pointer" @click="deleteFile(f)"></i>
                        </div>
                        <div>
                            <input type="file" multiple ref="attach" v-if="file_show">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-outline-primary" @click="save()">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: "supplier-modal"
        },
        supplier_id: {
            default: null,
        }
    },
    data() {
        return {
            supplier: {},
            types_of_account: ["普通", "当座", "貯蓄", "その他"],
            file_show: true,
        };
    },
    methods: {
        reload() {
            if (!this.supplier_id) {
                this.supplier = {};
                return;
            }
            axios.get("/master/suppliers/edit", {params: {id: this.supplier_id}}).then(res => {
                this.supplier = res.data;
            }).catch(err => {Alert.err(err)});
        },
        save() {
            const formData = new FormData();
            for (let i in this.$refs.attach.files) {
                const file = this.$refs.attach.files[i];
                formData.append(i, file);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            axios.post("/master/suppliers/upload", formData, config).then(res => {
                this.supplier.paths = res.data;
                axios.post("/master/suppliers/save", this.supplier).then(res => {
                    Alert.msg(res.data);
                    $("#" + this.id).modal("hide");
                    this.$emit('saved');
                }).catch(err => {
                    Alert.err(err);
                });
            }).catch(err => {
                Alert.err(err);
            });
        },
        deleteFile(v) {
            swal({
                title: "添付ファイル削除",
                text: `添付ファイル「${v.file_name}」を削除します。\nよろしいですか？`,
                icon: "error",
                buttons: ["キャンセル" , "削除"],
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/suppliers/deletefile", v).then(res => {
                        Alert.msg(res.data);
                        this.reload();
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
    },
    mounted() {
        $(`#${this.id}`).on("show.bs.modal", (e) => {
            this.reload();
        });
        $(`#${this.id}`).on("shown.bs.modal", (e) => {
            $("#supplier-name").focus();
        });
    }
}
</script>