<template>
    <datepicker
        :modelValue="modelValue"
        @update:modelValue="$event => $emit('update:modelValue', $event)"
        :month-change-on-scroll="false"
        format="yyyy-MM-dd"
        :clearable="true"
        :auto-apply="true"
        :time-picker="false"
        locale="ja"
        :hide-navigation="['time']"
        model-type="yyyy-MM-dd"
        :min-date="minDate"
        :max-date="maxDate"
        :placeholder="placeholder"></datepicker>
</template>

<script>
export default {
    props: {
        placeholder: {
            default: ""
        },
        modelValue: {},
        minDate: {
            default: new Date(2000, 1, 1)
        },
        maxDate: {
            default: new Date(2099, 1, 1)
        },
    }
}
</script>