<template>
    <div class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" :id=id>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <i class="fa-solid fa-pencil"></i>
                        <span v-if="menu_id">メニュー編集</span>
                        <span v-else>メニュー追加</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label>表示名</label>
                        <input type="text" class="form-control" v-model="menu.caption">
                    </div>
                    <div class="mb-3">
                        <label>パス（パスが無いメニューは見出しになります）</label>
                        <input type="text" class="form-control" v-model="menu.path">
                     </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-outline-primary" @click="save()">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: "menu-modal"
        },
        menu_id: {
            default: null,
        }
    },
    data() {
        return {
            menu: {},
            zero_menus: {},
        };
    },
    methods: {
        reload() {
            axios.get("/master/menus/menulists").then(res => {
                this.zero_menus = res.data;
            }).catch(err => {Alert.err(err)});

            if (!this.menu_id) {
                this.menu = {};
                return;
            } else {
                axios.get("/master/menus/edit", {params: {id: this.menu_id}}).then(res => {
                this.menu = res.data;
                }).catch(err => {Alert.err(err)});
            }
        },
        save () {
            axios.post("/master/menus/save", this.menu).then(res => {
                Alert.msg(res.data);
                this.$emit('save');
                $("#" + this.id).modal("hide");
            }).catch(err => {
                Alert.err(err.response);
            });
        }
    },
    mounted() {
        $(`#${this.id}`).on("show.bs.modal", (e) => {
            this.reload();
        });
    }
}
</script>