<template>
    <div class="outline" :class="{'closed': isClosed}">
        <div class="open-close" :class="{'closed': isClosed}" @click="toggle()">
            <span v-if="isClosed">
                <i class="fa-solid fa-arrow-right"></i>
            </span>
            <span v-else>
                <i class="fa-solid fa-arrow-left"></i>
                閉じる
            </span>
        </div>
        <div class="sidemenu" :class="{'closed': isClosed}">
            <div class="megamenus mx-3 fs-4" v-for="mm in menus" :key="mm.id" :class="{hide: isClosed}" style="cursor: pointer;">
                <div v-if="mm.path == null" class="fw-bold mt-3">
                    {{mm.caption}}
                </div>
                <div v-else>
                    <router-link :to="mm.path"
                    style="margin-left:-12px;"
                    class="text-start btn btn-link py-2 link-dark fs-6 path"
                    :class="{
                        active: isActive(mm.caption),
                        disabled: !hasPath(mm)
                    }">
                        <span :class="{
                            'fw-bold': $route.path.match(new RegExp(mm.path)),
                            'disabled_color': !hasPath(mm)
                        }">
                            {{mm.caption}}
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.megamenus {
    background-color: white;
}
.menu {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.font-light {
    color: #444;
    &:hover {
        color: #222;
    }
}
.disabled_color {
    color:#dcdcdc
}
.now-menu {
    width: 260px;
    text-align: center;
    cursor: pointer;
    padding: .5rem;
    background-color: white;
    transition: 0.2s ease;
    &:hover {
        background-color: #eee;
    }
    &.closed {
        width: 34px;
    }
}
.path {
    text-decoration: none;
    padding-top:6px;
    padding-bottom:6px;
    border-radius:30px;
    padding-left: 12px;
    padding-right: 12px;
    display: block;
    &:hover {
        background-color: #eee;
        color: #212529;
    }
}
.outline {
    height: 100vh;
    width: 260px;
    top: 0px;
    left: 0px;
    position: fixed;
    overflow-x: visible;
    overflow-y: visible;
    background-color: white;
    transition: 0.2s ease;
    z-index: 999;
    &.closed {
        width: 34px;
    }
    .open-close {
        width: 260px;
        text-align: center;
        cursor: pointer;
        padding: .5rem;
        background-color: white;
        transition: 0.2s ease;
        &:hover {
            background-color: #eee;
        }
        &.closed {
            width: 34px;
        }
    }
    .sidemenu {
        position: fixed;
        height: 100vh;
        width: 260px;
        overflow-x: auto;
        overflow-y: auto;
        transition: 0.2s ease;
        &.closed {
            width: 34px;
        }
    }
    .hide {
        display: none !important;
    }
}
@media screen and (max-width: 770px) {
    .outline {
        padding-top: 5rem;
    }
}
</style>

<script>
export default {
    data() {
        return {
            menus: [],
            isClosed: false,
            selected_megamenu: null,
            selected_menu: null,
            selected_path: null,
        }
    },
    methods: {
        loadMenus() {
            axios.get("/master/menus/lists").then(res => {
                this.menus = res.data;
            }).catch(err => {
                Alert.err(err);
            });
        },
        toggle() {
            this.isClosed = !this.isClosed;
            this.$emit("toggle", this.isClosed);
            // サイドバー開閉をcookieに保存
            document.cookie = "sidebar=" + this.isClosed;
        },
        hasPath(v) {
            if (!this.$store.state.user) return false;
            if (this.$store.state.user.is_root) return true;
            return _.find(this.$store.state.user.zero_paths, {id: v.id});
        },
        isActive(path) {
            const regex = new RegExp("^" + path);
            if (this.$route.path.match(regex)) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.loadMenus();
        if (document.cookie) {
            // サイドバー開閉をcookieから読み出し
            let cookies = document.cookie.split(/;/);
            for (const cookie of cookies) {
                let pair = cookie.split(/=/);
                if (pair[0] === "sidebar") {
                    if (pair[1] == "false") {
                        this.isClosed = false;
                    } else {
                        this.isClosed = true;
                    }
                    this.$emit("toggle", this.isClosed);
                }
            }
        }
    }
}
</script>
