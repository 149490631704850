<template>
    <div class="container-fluid">
        <legend>管理権限マスタ</legend>
        <div class="container">
            <div class="mb-3 d-flex justify-content-center">
                <span class="text-danger fs-1">管理者新規追加時はかならずシステム部に出力権限付与を依頼！</span>
            </div>

            <div class="mb-3 d-flex justify-content-end">
                <button class="btn btn-outline-secondary" @click="edit()">
                    <i class="fa-solid fa-plus"></i>
                    新規追加
                </button>
            </div>
            <div class="sticky mt-3">
                <pagination v-bind="managers" @goto="goto($event)" :showTotal="true"></pagination>
            </div>
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>管理者</th>
                            <th>対象者</th>
                            <th class="btn-th">抹消</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v, i) in managers.data" :key="i">
                            <td>{{v.user.name}}</td>
                            <td>{{v.check_user.name}}</td>
                            <td>
                                <button class="btn btn-outline-danger" type="button" @click="remove(v)">
                                    <span class="fa-solid fa-xmark"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <manager-editor
            ref="managerEditor"
            :manager_id="manager_id"
            @reload="reload"></manager-editor>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import managerEditor from "./managerEditor";
export default {
    components: {
        managerEditor,
    },
    data() {
        return {
            managers: [],
            isLoading: false,
            manager_id: null,
            managerEditor: null,
        }
    },
    methods: {
        edit(v = null) {
            if (v) {
                this.manager_id = v.manager_id;
            }
            setTimeout(() => {this.managerEditor.show()});
        },
        reload() {
            this.isLoading = true;
            axios.get("/master/managers/lists").then(res => {
                this.managers = res.data;
            }).catch(err => {
                Alert.err(err.response);
            }).finally(() => {
                this.isLoading = false;
            })
        },
        remove (item) {
            swal({
                title: "稟議権限抹消",
                text: "指定の稟議権限を抹消します。\n抹消すると元に戻せません。\nよろしいですか？",
                icon: "error",
                buttons: ["キャンセル", "抹消"],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post("/master/managers/remove", {id: item.id}).then(res => {
                        Alert.msg(res.data);
                        this.reload();
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        // ページ遷移
        goto(page) {
            this.params.page = page;
            this.reload();
        },

    },
    mounted() {
        this.reload();
        this.managerEditor = new bootstrap.Modal(document.getElementById('manager-editor'));
    }
}
</script>