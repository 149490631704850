<template>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center" v-if="last() > 1 || always">
                <li class="page-item" :class="{'disabled': prev() <= 0}"><a class="page-link" @click="goto(1)"><span class="fa-solid fa-backward-fast"></span></a></li>
                <li class="page-item" :class="{'disabled': prev() <= 0}"><a class="page-link" @click="goto(prev())"><span class="fa-solid fa-backward"></span></a></li>
                <!-- <template v-for="n in range()"> -->
                    <li v-for="n in range()" class="page-item" :key="n" :class="{'active': n == page()}"><a class="page-link" @click="goto(n)">{{ n }}</a></li>
                <!-- </template> -->
                <li  class="page-item" v-if="showTotal"><a class="page-link" ><b>{{$filters.numberFormat(total )}} 件</b></a></li>
                <li class="page-item" :class="{'disabled': page() >= last()}"><a class="page-link" @click="goto(next())"><span class="fa-solid fa-forward"></span></a></li>
                <li class="page-item" :class="{'disabled': page() >= last()}"><a class="page-link" @click="goto(last())"><span class="fa-solid fa-forward-fast"></span></a></li>
            </ul>
        </nav>
</template>

<style scoped>
.page-link {
    cursor: pointer;
}
</style>

<script>
    export default {
        props: {
            current_page: {},
            last_page: {},
            total: {},
            size: {
                default: 13,
                validator(value) {
                    if (value >= 7 && value <= 21) {
                        if (value % 2 == 1)
                            return true;
                    }
                    return false;
                }
            },
            showTotal: {
                default: false
            },
            always: false,
        },
        methods: {
            page() {
                return this.current_page;
            },
            prev() {
                return this.current_page - 1;
            },
            next() {
                if (this.current_page + 1 > this.last_page)
                    return this.last_page
                else
                    return this.current_page + 1
            },
            last() {
                return this.last_page;
            },
            all() {
                return this.total;
            },
            goto(n) {
                this.$emit('goto', n);
            },
            range () {
                let size = parseInt(this.size);
                let start = _.max([this.page() - Math.floor(size / 2), 1]);
                let end   = _.min([start + size, this.last() + 1]);
                start     = _.max([end - size, 1]);
                let range = _.range(start, end);
                return range;
            },
        }
    }
</script>