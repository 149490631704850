<template>
    <div class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" :id=id>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span class="fa-solid fa-pencil"></span>
                        <span v-if="approval.id">稟議編集</span>
                        <span v-else>稟議追加</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3" v-if="is_approval">
                        <label>ユーザー名</label>
                        <div>
                            <vue-select
                                class="form-select"
                                :options="users"
                                label-by="name"
                                value-by="id"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                placeholder="ユーザー名を選択"
                                search-placeholder="文字入力で検索"
                                v-model="approval.user_id"></vue-select>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>発注先</label>
                        <div>
                            <vue-select
                                class="form-select"
                                :options="suppliers"
                                label-by="supplier_name"
                                value-by="id"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                placeholder="発注先を選択"
                                search-placeholder="文字入力で検索"
                                v-model="approval.zero_supplier_id"></vue-select>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>受取手段</label>
                        <div>
                            <select v-model="approval.zero_approval_acceptance_id" class="form-select">
                                <option v-for="a in acceptances" :key="a.id" :value="a.id">
                                    {{a.acceptance}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="is_approval">
                        <div class="col-md-6">
                            <label>分類</label>
                            <select v-model="approval.zero_approval_classification_id" class="form-select" @change="reloadClassificationDetails()">
                                <option v-for="c in classifications" :key="c.id" :value="c.id">
                                    {{c.classification_name}}
                                    <span v-if="c.details_count > 0">({{c.details_count}})</span>
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label>分類詳細</label>
                            <select v-model="approval.zero_approval_classification_detail_id" class="form-select">
                                <option v-for="c in classification_details" :key="c.id" :value="c.id">
                                    {{c.classification_detail_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>内容</label>
                        <input type="text" v-model="approval.content" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label>金額</label>
                        <input type="number" v-model="approval.money" class="form-control">
                        <div class="breakdown">
                            <label :class="{'text-danger': approval.money != breakdownTotal}">
                                内訳: {{$filters.numberFormat(breakdownTotal)}}
                                (差額: {{$filters.numberFormat(diffTotal)}})
                            </label>
                            <button type="button" class="btn btn-outline-success" @click="equalDivision()">等分</button>
                            <span style="color:#ff0000;">※代理業は等分に含まれない</span>
                            <div class="d-flex flex-wrap">
                                <div v-for="c in categories" :key="c.id" class="m-2">
                                    <label>{{c.categorie_name}}</label>
                                    <input type="number" v-model="breakdowns[c.id]" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>添付ファイル</label>
                        <div v-for="f in approval.zero_approval_file" :key="f.id" class="file-list">
                            {{f.file_name}}
                            <i v-if="!approval.is_approval" class="fa-solid fa-xmark text-danger fw-bold pointer" @click="deleteFile(f)"></i>
                        </div>
                        <div>
                            <input type="file" multiple ref="attach" v-if="file_show">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label>記入日</label>
                            <single-picker
                                id="entry-date"
                                placeholder="記入日"
                                v-model="approval.entry_date"
                                :autoApply="true"
                                :max-date="new Date()"></single-picker>
                        </div>
                        <div class="col-md-6">
                            <label>支払予定日</label>
                            <single-picker
                                id="payment-schedule-date"
                                placeholder="支払予定日"
                                v-model="approval.payment_schedule_date"
                                :autoApply="true"
                                :min-date="yesterday"></single-picker>
                        </div>
                    </div>
                    <div class="row" v-if="is_approval">
                        <div class="col-md-">
                            <label>対象月</label>
                            <div class="dropdown">
                                <input type="text" v-model="approval.target_month"
                                    class="form-control dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    placeholder="対象月をYYYYMM形式で入力">
                                <ul class="dropdown-menu">
                                    <li v-for="tm in target_months" :key="tm.value">
                                        <a href="#" class="dropdown-item" @click="approval.target_month = tm.value">{{tm.label}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="approval.is_approval && is_approval" type="button" class="btn btn-outline-danger" @click="cancelApproval()">承認取消</button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-outline-primary" @click="save()">保存</button>
                </div>
            </div>
        </div>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style scoped lang="scss">
.file-list {
    margin: .5rem 0;
    padding: .5rem;
    border: 1px solid #ddd;
    border-radius: .3rem;
    i:hover {
        text-shadow: #e3342f 1px 0 10px;
    }
}
.breakdown {
    margin: 1rem 0 2rem;;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: .5rem
}
</style>

<script>
export default {
    props: {
        id: {
            default: "add-approval-modal",
        },
        approval_id: {
            default: null,
        },
        is_approval: {
            default: false,
        }
    },
    data() {
        return {
            approval: {
                user_id: null,
                zero_supplier_id: null,
            },
            suppliers: [],
            acceptances: [],
            categories: [],
            classifications: [],
            classification_details: [],
            breakdowns: {},
            today: dayjs().format("YYYY-MM-DD"),
            yesterday: dayjs().subtract(1, 'd').endOf('day').format("YYYY-MM-DD"),
            isLoading: false,
            target_months: [],
            file_show: true,
            users: [],
        };
    },
    methods: {
        init() {
            this.users = [];
            axios.get("/tools/approvals/userlists").then(res => {
                for (let i in res.data) {
                    let userlists = res.data[i];
                    this.users.push({
                        name: userlists.name + '(' + userlists.email + ')',
                        id: userlists.id,
                    });
                }
            });
           axios.get("/tools/approvals/supplierlists").then(res => {
                this.suppliers = res.data;
            }).catch(err => {Alert.err(err)});
            axios.get("/tools/approvals/acceptancelists").then(res => {
                this.acceptances = res.data;
            }).catch(err => {Alert.err(err)});
            axios.get("/tools/approvals/categories").then(res => {
                this.categories = res.data;
            }).catch(err => {Alert.err(err)});
            axios.get("/tools/approvals/classificationlists").then(res => {
                this.classifications = res.data;
            }).catch(err => {Alert.err(err)});
            // 対象月生成
            for (let i = -3; i <= 3; i++) {
                const d = dayjs().add(i, "month");
                this.target_months.push({
                    "value": d.format("YYYYMM"),
                    "label": d.format("YYYY年MM月"),
                });
            }
            this.reloadClassificationDetails();
        },
        reloadClassificationDetails() {
            this.approval.zero_approval_classification_detail_id = null;
            if (!this.approval.zero_approval_classification_id) {
                this.classification_details = [];
            }
            axios.get("/tools/approvals/classificationdetaillists", {params: {
                id: this.approval.zero_approval_classification_id
            }}).then(res => {
                this.classification_details = res.data;
            }).catch(err => {Alert.err(err)});
        },
        reload() {
            this.file_show = false;
            this.$nextTick(function () {
                this.file_show = true;
            });
            if (!this.approval_id) {
                this.approval = {
                    user_id: null,
                    zero_supplier_id: null,
                    entry_date: this.today,
                    payment_schedule_date: this.today,
                };
                this.breakdowns = {};
                return;
            }
            axios.get("/tools/approvals/edit", {params: {id: this.approval_id}}).then(res => {
                this.approval = res.data;
                this.breakdowns = {};
                for(const breakdown of this.approval.zero_breakdown) {
                    this.breakdowns[breakdown.zero_approval_categorie_id] = parseInt(breakdown.money);
                }
            }).catch(err => {Alert.err(err)});
        },
        save() {
            if (this.$store.state.file_upload < this.$refs.attach.files.length) {
                Alert.err("ファイル最大件数をオーバーしています")
                return;
            }
            const formData = new FormData();
            for (let i in this.$refs.attach.files) {
                const file = this.$refs.attach.files[i];
                formData.append(i, file);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            this.isLoading = true;
            this.approval.breakdowns = this.breakdowns;
            axios.post("/tools/approvals/upload", formData, config).then(res => {
                this.approval.paths = res.data;
                axios.post("/tools/approvals/save", this.approval).then(res => {
                    Alert.msg(res.data);
                    $("#" + this.id).modal("hide");
                    this.$emit('saved');
                    this.isLoading = false;
                }).catch(err => { Alert.err(err);this.isLoading = false; });
            }).catch(err => { Alert.err(err);this.isLoading = false; });
        },
        deleteFile(v) {
            swal({
                title: "添付ファイル削除",
                text: `添付ファイル「${v.file_name}」を削除します。\nよろしいですか？`,
                icon: "error",
                buttons: ["キャンセル" , "削除"],
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/tools/approvals/deletefile", v).then(res => {
                        Alert.msg(res.data);
                        this.reload();
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        cancelApproval() {
            axios.post("/tools/approvals/cancelapproval", {id: this.approval_id}).then(res => {
                Alert.msg(res.data);
                $("#" + this.id).modal("hide");
                this.$emit('saved');
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        // 等分処理
        equalDivision() {
            // 代理業を除外する処理
            // http://project.socialte.ch/work_packages/2709/activity
            var check_categories = Object.assign({}, this.categories);
            for (var key in check_categories) {
                if (check_categories[key].id == 10) {
                    delete check_categories[key];
                }
            }
           // loopする回数を設定
            let categori_count = Object.keys(check_categories).length;
            // 商
            let quotient  = Math.floor(this.approval.money / categori_count);
            // 余り
            let remainder = ((this.approval.money % categori_count) + categori_count) % categori_count;
            // カテゴリ分loopする
            for(var c in check_categories) {
                // 商を設定
                this.breakdowns[check_categories[c].id] = quotient;
                // 余りが1以上の場合、1追加する。余りが0まで行う
                if (remainder > 0) {
                    this.breakdowns[check_categories[c].id] += 1;
                    remainder -= 1;
                }
            }
        }
    },
    computed: {
        breakdownTotal() {
            let total = 0;
            for (const key of Object.keys(this.breakdowns)) {
                total += this.breakdowns[key];
            }
            return total;
        },
        diffTotal() {
            return this.approval.money - this.breakdownTotal;
        },
    },
    mounted() {
        this.init();
        $(`#${this.id}`).on("show.bs.modal", (e) => {
            this.reload();
        });
    }
}
</script>