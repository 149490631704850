
export default {
    wrap(chars) {
        if (!chars) {
            return null;
        }
        return chars.toString().replace(/\r\n|\r|\n/g, '<br />');
    },
    wrapSpace(chars) {
        if (!chars) {
            return null;
        }
        return chars.toString().replace(/[ 　]/g, '<br />');
    },
    old_chars(chars) {
        if (!chars) {
            return null;
        }
        return chars.toString().replace(/([髙﨑國嵜圓壱弐ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ]+)/g, ' <big>$1</big> ');
    },
    numberFormat(num) {
        if (!num)
            return 0;
        return num.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
    },
    currency(num) {
        if (!num)
            return 0;
        return num.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, "$1<span class='nocopy'>,</span>");
    },
    truncate(string, length = 60) {
        if (string && string.length > length) {
            string = string.substr(0, length) + "...";
        }
        return string
    },
    format(string, format) {
        if (!format) {
            format = "YYYY-MM-DD HH:mm";
        }
        let m = dayjs(string);
        if (m.isValid())
            return m.format(format);
        return string;
    },
    formatTime(string, format) {
        if (!format) {
            format = "YYYY-MM-DD HH:mm:ss";
        }
        let m = dayjs(string);
        if (m.isValid())
            return m.format(format);
        return string;
    },
    to_date(num) {
        if (!num) {
            return "-";
        }
        return num.toString().replace(/\d{2}:\d{2}:\d{2}/g, '');
    },
    zip(zip) {
        if (!zip)
            return null;
        return zip.substr(0, 3) + "-" + zip.substr(3, 4);
    },
}
