<template>
    <div class="container">
        <legend>メニューマスタ</legend>
        <div class="mb-3">
            ※パスを指定すると、子メニューになります。親子関係はありません。
        </div>
        <div class="me-3 mt-3 text-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <span class="fa-solid fa-plus"></span>
                新規追加...
            </button>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table-hover mt-3">
                <thead>
                    <tr>
                        <th class="btn-th"></th>
                        <th class="btn-th">編集</th>
                        <th>表示名</th>
                        <th>パス</th>
                        <th class="btn-th">抹消</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="v in ordered" :key=v.id
                        :class="{'deleted': v.deleted_at}"
                        class="draggable"
                        draggable="true"
                        @dragstart="dragstart(v, $event)"
                        @dragend="dragend"
                        @dragenter="dragenter(v)">
                        <td>
                            <i class="fa-solid fa-grip-vertical"></i>
                        </td>
                        <td>
                            <a class="btn btn-outline-secondary" @click="edit(v.id)">
                                <span class="fa-solid fa-pencil"></span>
                            </a>
                        </td>
                        <td>{{v.caption}}</td>
                        <td>{{v.path}}</td>
                        <td>
                            <button class="btn btn-outline-danger" type="button" @click="remove(v)">
                                <span class="fa-solid fa-xmark"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <menu-editor
            :menu_id="menu_id"
            @save="reload()">
        </menu-editor>
    </div>
</template>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>

<script>
import menuEditor from './menusEditor.vue'

export default {
    components: {
        menuEditor,
    },
    data() {
        return {
            menus: [],
            menu_id: null,
            menuModal: null,
        }
    },
    methods: {
        reload () {
            axios.get("/master/menus/lists").then(res => {
                this.menus = res.data;
            }).catch(err => {Alert.err(err)});
        },
        edit (id = null) {
            this.menu_id = id
            setTimeout(() => this.menuModal.show());
        },
        remove (item) {
            swal({
                title: "メニュー抹消",
                text: "指定のメニューを抹消します。\n抹消すると元に戻せません。\nよろしいですか？",
                icon: "error",
                buttons: ["キャンセル", "抹消"],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post("/master/menus/remove", {id: item.id}).then(res => {
                        Alert.msg(res.data);
                        this.reload();
                    }).catch(err => { Alert.err(err) }); 
                }
            });
        },
        // ドラッグStart
        dragstart(item, e) {
            this.drag = {};
            // 掴んでいる情報を設定
            this.drag = item;
        },
        // ドラッグend
        dragend(e) {
            axios.post("/master/menus/sort", {menus: this.menus}).then(res => {
                this.reload();
            }).catch(err => {
                Alert.err(err);
            });
        },
        // ドラッグenter
        dragenter(item) {
            // 最後にホバーしたIDを記録
            let tmp = item.order_number
            item.order_number = this.drag.order_number
            this.drag.order_number = tmp
            return
        },
    },
    computed: {
        ordered() {
            return _.orderBy(this.menus, "order_number");
        }
    },
    mounted() {
        this.menuModal = new bootstrap.Modal(document.getElementById("menu-modal"));
        this.reload()
    },
}
</script>
