<template>
    <div class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" :id=id>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span class="fa-solid fa-pencil"></span>
                        カテゴリ編集
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label>カテゴリ名</label>
                    <input type="text" v-model="category.categorie_name" class="form-control" placeholder="カテゴリ名" id="category-name">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-outline-primary" @click="save()">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: "category-modal",
        },
        category_id: {
            default: null,
        },
    },
    data() {
        return {
            category: {}
        }
    },
    methods: {
        reload() {
            if (!this.category_id) {
                this.category = {};
                return;
            }
            axios.get("/master/approval/categories/edit", {params: {id: this.category_id}}).then(res => {
                this.category = res.data;
            }).catch(err => {Alert.err(err)});
        },
        save () {
            axios.post("/master/approval/categories/save", this.category).then(res => {
                Alert.msg(res.data);
                $("#" + this.id).modal("hide");
                setTimeout(() => {this.$emit('saved')}, 300);
            }).catch(err => {
                Alert.err(err);
            });
        }
    },
    mounted() {
        $(`#${this.id}`).on("show.bs.modal", (e) => {
            this.reload();
        });
        $(`#${this.id}`).on("shown.bs.modal", (e) => {
            $("#category-name").focus();
        });
    }
}
</script>