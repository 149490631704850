require('./bootstrap');
require('bootstrap-notify').default;

import { createApp } from 'vue';

const app = createApp({
    created() {
        this.$store.commit("setUserId", parseInt($("#user_id").val()));
        this.$store.commit("setIsRoot", $("#is_root").val());
        this.$store.commit("setFileUpload", $("#file_upload").val());
        this.$store.commit("setIsDebug", $("#is_debug").val());
        axios.get("/auth/user").then(res => {
            this.$store.commit("setUser", res.data);
        }).catch(err => {Alert.err(err)});
    },
});

app.component('pagination', require('components/pagination').default);

// フィルタ関係
import filters from "./filter";
app.config.globalProperties.$filters = filters;

// 日付選択
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
app.component('datepicker', datepicker);
app.component("singlePicker", require("components/singlePicker").default);
app.component("rangePicker", require("components/rangePicker").default);

// ローディング
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
app.component('Loading', Loading);

// セレクタ
import VueNextSelect from 'vue-next-select';
app.component('vue-select', VueNextSelect);

// 日時
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);
import 'dayjs/locale/ja';
dayjs.locale('ja');
window.dayjs = dayjs;

import swal from 'sweetalert';
window.swal = swal;

import Alert from './alert';
window.Alert = Alert;

import router from "./router";
app.use(router);

import store from "./store";
app.use(store);

app.mount("#app")