<template>
    <nav class="navbar navbar-default fixed-top bg-light" style="padding-top: 0px;padding-bottom: 0px;height: 50px;">
        <div class="container-fluid">
            <!-- 左寄せ部分 -->
            <nav class="navbar navbar-expand-lg navbar-light pt-0">
                <router-link class="navbar-brand" to="/" style="padding-top: 0px;">
                    <img src="/img/icons/zero.png" style="width:20px;height:20px;display:inline;">
                    zero
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
            </nav>

            <!-- 右寄せ部分 -->
            <nav class="navbar navbar-expand-lg navbar-light justify-content-center pt-0">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false"  style="text-decoration: none;">
                                <i class="fa-solid fa-gear fa-spin"></i>
                                    {{userName}} <span class="caret"></span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" role="menu">
                                <li>
                                    <a href="#" class="dropdown-item" @click.prevent="logout()">
                                        <span class="fa-solid fa-right-from-bracket"></span>
                                        ログアウト
                                    </a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </nav>
</template>

<style scoped>
.fixed-top {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    border-radius: 0;
    border: 0px;
    margin-bottom: 0;
}
</style>

<script>
export default {
    data() {
        return {
            recents: [],
        }
    },
    methods: {
        logout() {
            axios.post("/logout").then(res => {
                location.href = "/";
            }).catch(err => {
                Alert.err(err);
            });
        },
        recent() {
            axios.get("/recents/lists").then(res => {
                let paths = res.data;
                this.recents = [];
                for (let i = 0; i < paths.length; i++) {
                    if (i > 0 && i % 5 === 0) {
                        this.recents.push({id: i + "-divider"});
                    }
                    this.recents.push(paths[i]);
                }
            }).catch(err => {
                Alert.err(err);
            });
        },
        linkTo(path) {
            this.$router.push(path);
        },
    },
    computed: {
        userName() {
            if (this.$store.state.user) {
                return this.$store.state.user.name;
            }
            return "ユーザー名";
        }
    },
    mounted() {
        this.recent();
    }
}
</script>
