import { createStore } from 'vuex'

export default createStore({
    state: {
        brand: {},
        // 初期データ
        user_id: null,
        is_root: null,
        file_upload: null,
        is_debug: null,
        user: null,
    },
    mutations : {
        reloadBrand(state, brand) {state.brand = brand;},
        setUserId(state, id) {state.user_id = id;},
        setIsRoot(state, is_root) {state.is_root = is_root;},
        setFileUpload(state, file_upload) {state.file_upload = file_upload;},
        setIsDebug(state, is_debug) {state.is_debug = is_debug;},
        setUser(state, user) {state.user = user;},
    },
    actions: {
        async reloadBrand(context, brand_code) {
            context.commit("reloadBrand", brand_code);
        }
    },
    getters: {
        userId(state) { return state.user_id; },
        isRoot(state) { return state.isRoot; },
        fileUpload(state) { return state.file_upload; },
        isDebug(state) { return state.isDebug; },
        user(state) { return state.user; },
    }
});
