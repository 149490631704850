<template>
    <div class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" :id=id>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                            {{supplier.supplier_name}}
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>ダウンロード</th>
                                <th>プレビュー</th>
                                <th>ファイル名</th>
                                <th style="width:6rem" v-if="!is_approval">抹消</th>
                            </tr>
                        </thead>
                        <tbody v-if="supplier.zero_contracts && supplier.zero_contracts.length > 0">
                            <tr v-for="f in supplier.zero_contracts" :key="f.id">
                                <td>
                                    <button class="btn btn-outline-secondary" @click="download(f)">
                                        <i class="fa-solid fa-download"></i>
                                    </button>
                                </td>
                                <td>
                                    <button class="btn btn-outline-success" @click="preview(f)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                                <td>
                                    {{f.contract_name}}
                                </td>
                                <td v-if="!is_approval">
                                    <button class="btn btn-outline-danger" @click="remove(f)">
                                        <i class="fa-solid fa-xmark"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4">
                                    添付ファイルはありません。
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">閉じる</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import uploader from "components/uploader";
export default {
    components: {
        uploader
    },
    props: {
        id: {
            default: "download-modal"
        },
        supplier_id: {
            default: null,
        },
        is_approval: {
            default: true,
        }
    },
    data() {
        return {
            supplier: {},
        }
    },
    methods: {
        uploaded() {
            this.$emit("changed");
            this.reload();
        },
        reload() {
            if (!this.supplier_id) {
                Alert.err("発注先が指定されていません。");
                $("#" + this.id).modal("hide");
                return;
            }
            axios.get("/master/suppliers/edit", {params: {id: this.supplier_id}}).then(res => {
                this.supplier = res.data;
            }).catch(err => {Alert.err(err)});
        },
        download(v) {
            location.href = `/master/suppliers/download/?id=${v.id}`;
        },
        remove(v) {
            swal({
                title: "添付ファイル削除",
                text: `添付ファイル「${v.file_name}」を削除します。\nよろしいですか？`,
                icon: "error",
                buttons: ["キャンセル" , "削除"],
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/tools/approvals/deletefile", v).then(res => {
                        Alert.msg(res.data);
                        this.reload();
                        this.$emit("changed");
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        preview(v) {
            const url = `/master/suppliers/previewfile/?id=${v.id}`;
            window.open(url, '_blank')
        },
    },
    mounted() {
        $(`#${this.id}`).on("show.bs.modal", (e) => {
            this.reload();
        });
    }
}
</script>