<template>
    <div class="container-fluid">
        <legend class="mb-3 d-flex justify-content-between">
            稟議出力
            <button  v-if="approval_user" class="btn btn-outline-danger" @click="downloadAll()" :disabled="downloading">
                <i class="fa-solid fa-cloud-arrow-down"></i>
                全稟議出力
            </button>
        </legend>
        <div class="container">
            <div class="row justify-content-center mb-3">
                <div class="col-md-10">
                    <input type="search" v-model="keywords" class="form-control form-control-lg" @keydown="find($event)" placeholder="検索">
                </div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="small text-muted">
                                支払予定日(上限200件)
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <single-picker
                                id="payment-schedule-date"
                                placeholder="支払予定日(上限200件)"
                                v-model="payment_schedule_date"
                                :autoApply="true"></single-picker>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-outline-success nowrap" @click="downloadPayment()">
                                <i class="fa-solid fa-download"></i>
                                振込出力
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="small text-muted">
                                支払予定日の期間
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <range-picker
                                id="period-of-payment_schedule"
                                placeholder="支払予定日の期間"
                                v-model="period_of_payment_schedule"
                                :autoApply="true"></range-picker>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-outline-primary nowrap" @click="downloadBreakdown()">
                                <i class="fa-solid fa-calendar"></i>
                                内訳出力
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="btn-group" role="group">
                    <label class="btn btn-outline-primary" :class="{active: user_mode == 'self'}" @click="setUserMode('self')">
                        自身
                    </label>
                    <label class="btn btn-outline-primary" :class="{active: user_mode == 'manager'}" @click="setUserMode('manager')">
                        管理下
                    </label>
                    <label v-if="approval_user" class="btn btn-outline-primary" :class="{active: user_mode == 'all'}" @click="setUserMode('all')">
                        全ユーザー
                    </label>
                </div>
            </div>
        </div>

        <div class="sticky mb-3">
            <pagination v-bind="approvals" @goto="goto($event)" :showTotal="true"></pagination>
        </div>

        <div class="table-responsive-lg">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="nowrap">添付ファイル</th>
                        <th class="nowrap">ユーザー名</th>
                        <th class="nowrap">発注先名</th>
                        <th class="nowrap">受取手段</th>
                        <th class="nowrap">分類/詳細</th>
                        <th class="nowrap">内容</th>
                        <th class="nowrap">金額(内訳)</th>
                        <th class="sortable nowrap" @click="sort('entry_date')">
                            記入日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'entry_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'entry_date' && !isAsc"></i>
                        </th>
                        <th class="sortable nowrap" @click="sort('payment_schedule_date')">
                            支払予定日
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'payment_schedule_date' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'payment_schedule_date' && !isAsc"></i>
                        </th>
                        <th class="sortable nowrap" @click="sort('target_month')">
                            対象月
                            <i class="fa-solid fa-arrow-up" v-if="orderBy == 'target_month' && isAsc"></i>
                            <i class="fa-solid fa-arrow-down" v-else-if="orderBy == 'target_month' && !isAsc"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="a in approvals.data" :key="a.id" :class="{'deleted': a.deleted_at}">
                        <td>
                            <button class="btn btn-sm" :class="{
                                'btn-outline-secondary': a.zero_approval_file.length == 0,
                                'btn-outline-info': a.zero_approval_file.length > 0,
                            }" @click="download(a)" :disabled="a.deleted_at">
                                <i class="fa-solid fa-paperclip"></i>
                                {{a.zero_approval_file.length}} 件
                            </button>
                        </td>
                        <td class="small">
                            {{a.user.name}}
                        </td>
                        <td>
                            {{a.zero_supplier.supplier_name}}
                        </td>
                        <td>
                            {{a.zero_approval_acceptance.acceptance}}
                        </td>
                        <td class="small">
                            <span v-if="a.zero_approval_classification">
                                {{a.zero_approval_classification.classification_name}}
                            </span>
                            <span v-if="a.zero_approval_classification_detail">
                                / {{a.zero_approval_classification_detail.classification_detail_name}}
                            </span>
                        </td>
                        <td>
                            {{a.content}}
                        </td>
                        <td>
                            {{$filters.numberFormat(a.money)}}
                            <div class="small">
                                ({{totalBreakdown(a.zero_breakdown)}})
                            </div>
                        </td>
                        <td class="small nowrap">
                            {{a.entry_date}}
                        </td>
                        <td class="small nowrap">
                            {{a.payment_schedule_date}}
                        </td>
                        <td class="small nowrap">
                            {{ym(a.target_month)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <download-approval
            :approval_id="approval_id"
            :is_approval="is_approval"
            @changed="reload()"></download-approval>
        <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"></loading>
    </div>
</template>

<style scoped lang="scss">
.sortable {
    color: darkgreen;
    cursor: pointer;
    &:hover {
        background-color: #fbebee;
    }
}
    .schedule {
        height: 30px;
    }
    .export-button {
        margin-left: 10px;
    }
</style>

<script>
import downloadApproval from '../downloadApproval/downloadApproval.vue'
export default {
    components: {
        downloadApproval,
    },
    data() {
        return {
            approvals: {},
            approval_id: null,
            approvalModal: null,
            downloadModal: null,
            keywords: null,
            page: parseInt(this.$route.query.page || 1),
            orderBy: null,
            isAsc: true,
            isLoading: false,
            payment_schedule_date: dayjs().format("YYYY-MM-DD"),
            period_of_payment_schedule: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
            downloading: false,
            approval_user: false,
            is_approval: 0,
            count_unapproved: 0,
            user_mode: "manager",
        }
    },
    methods: {
        find(ev) {
            if (!ev.isComposing && ev.code == "Enter") {
                this.page = 1;
                this.reload();
            }
        },
        sort(orderBy = null) {
            if (orderBy && this.orderBy == orderBy) {
                if (this.isAsc) {
                    this.isAsc = false;
                } else {
                    this.orderBy = null;
                    this.isAsc = true;
                }
            } else {
                this.orderBy = orderBy;
                this.isAsc = true;
            }
            this.reload();
        },
        goto(n = 1) {
            this.page = n;
            this.reload();
        },
        reload() {
            this.isLoading = true;
            axios.get("/tools/approvals/addlists", {params: {
                keywords: this.keywords,
                page: this.page,
                orderBy: this.orderBy,
                isAsc: this.isAsc ? 1 : 0,
                user_mode: this.user_mode,
            }}).then(res => {
                this.approvals = res.data;
                this.isLoading = false;
            }).catch(err => {Alert.err(err); this.isLoading = false;});
        },
        edit(id = null) {
            this.approval_id = id;
            setTimeout(() => this.approvalModal.show());
        },
        download(v) {
            this.approval_id = v.id;
            this.is_approval = v.is_approval;
            setTimeout(() => this.downloadModal.show());
        },
        downloadPayment() {
            if (!this.payment_schedule_date) {
                Alert.err("支払予定日が選択されていません");
                return;
            }
            axios.post("/tools/approvals/transfer", {date: this.payment_schedule_date}).then(res => {
                location.href = `/tools/approvals/transferdownload/?date=${this.payment_schedule_date}&user_mode=${this.user_mode}`;
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        downloadBreakdown() {
            if (!this.period_of_payment_schedule ||  this.period_of_payment_schedule.length != 2) {
                Alert.err("支払予定日が選択されていません");
                return;
            }
            axios.post("/tools/approvals/checkbreakdownload", {date: this.period_of_payment_schedule}).then(res => {
                location.href = "/tools/approvals/breakdowndownload/?date=" + encodeURI(this.period_of_payment_schedule) + `&user_mode=${this.user_mode}`;
            }).catch(err => {
                Alert.err(err.response);
            });
        },
        downloadAll() {
            this.downloading = true;
            setTimeout(() => {this.downloading = false}, 10 * 1000);
            location.href = "/tools/approvals/allapprovaldownload";
        },
        uploaded() {
            this.reload();
        },
        totalBreakdown(breakdowns) {
            return this.$filters.numberFormat(_.sumBy(breakdowns, (o) => {
                return parseInt(o.money);
            }));
        },
        ym(ym) {
            if (!ym) return "";
            const year = ym.toString().substring(0, 4);
            const month = ym.toString().substring(4, 6);
            return year + "年" + month + "月";
        },
        setUserMode(i) {
            this.user_mode = i;
            this.reload();
        },
    },
    watch: {
        keywords(n, o) {
            if (o && o.length > 0 && n.length === 0) {
                this.reload();
            }
        }
    },
    mounted() {
        axios.get("/tools/approvals/checkuser").then(res => {
            this.approval_user = res.data;
            if (this.approval_user) {
                this.user_mode = "all";
            }
            this.reload();
        }).catch(err => {Alert.err(err); this.isLoading = false;});

        this.downloadModal = new bootstrap.Modal(document.getElementById("download-modal"));
    },
}
</script>
