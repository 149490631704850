<template>
    <div class="container">
        <legend class="d-flex justify-content-center mb-3">
            zero - 稟議管理システム
        </legend>
        <div>
            <h3 class="header">ツール</h3>
            <p>稟議を取り扱うメニューです。</p>
            <h4>稟議提出</h4>
            <p>
                稟議を追加して、承認者へ提出します。
                自分自身が追加した稟議しかリストされません。
            </p>
            <h4>稟議承認</h4>
            <p>
                承認者用のメニューです。
                稟議の分類指定、承認、出力を行います。
            </p>
            <h4>内訳記入</h4>
            <p>
                内訳の記入機能です。
            </p>
            <h3 class="header">マスタ</h3>
            <p>マスタ関係です。管理者用のメニューです。</p>
            <h4>メニューマスタ</h4>
            <p>
                zeroのメニュー管理機能です。
                原則システム部が操作します。
            </p>
            <h4>発注先マスタ</h4>
            <p>
                発注先のマスタ管理です。
            </p>
            <h4>稟議カテゴリマスタ</h4>
            <p>
                「稟議カテゴリ」となっていますが、内訳の種類の管理です。
            </p>
            <h4>分類マスタ</h4>
            <p>
                分類管理です。各分類から、子の分類詳細を編集できます。
            </p>
        </div>
    </div>
</template>

<style scoped>
.container {
    max-width: 64rem;
}
.header {
    border-bottom: 1px dotted #aaa;
    padding: .3rem 0;
}
</style>